<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <table class="table mb-2">
              <thead>
                <tr>
                  <th>Notification</th>
                  <th>Who recieves it</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notification in notifications"
                  :key="notification.id"
                >
                  <td>{{ notification.name }}</td>
                  <td>
                    <v-select
                      v-model="notification.shareds"
                      clearable
                      multiple
                      label="full_name"
                      :options="users"
                      :get-option-key="option => option.id"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="page-actions">
            <button
              v-if="!sending"
              id="type-success"
              class="btn btn-success"
              @click="save"
            >
              <i data-feather="save" />
              <span class="ms-25 align-middle">Save</span>
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Saving...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      title: 'Automatic notifications',
      sending: false,
      users: [],
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'autoNotifications/notifications',
    }),
  },
  async mounted() {
    this.$store.dispatch('autoNotifications/fetch')
    const name = { name: '', role: 'super-admin' }
    const resp = await Vue.prototype.$http.post(`${Vue.prototype.$identityUrl}/user/filter`, { name, ignore_pagination: true })
    this.users = resp.data.data
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async save() {
      this.sending = true
      await this.$store.dispatch('autoNotifications/update', this.notifications)

      Vue.swal('The auto-notification settings has been saved successfully', '', 'success')
      this.sending = false
    },
  },
}
</script>
